import React from "react";
import { Container, Grid, Card, CardContent, Typography, Button, CardActions, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';


const StyledCard = styled(Card)(({ theme }) => ({
  minHeight: 200,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: theme.shadows[5],
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundImage: 'linear-gradient(to right, #d96704, #e88c2a)',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundImage: 'linear-gradient(to right, #e88c2a, #d96704)',
  },
}));


const Home = () => {
  const user = useSelector((state) => state.user.user);
  const apiToken = useSelector((state) => state.user.apiToken);
  return (
    <Container maxWidth="lg">
       <Helmet>
        <title>Home</title>
        <meta name="description" content="Tuition Reimbursment" />
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        { user !== null && apiToken &&
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h5" component="div">
                  New Request for Me
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Submit a new tuition reimbursement request for yourself.
                </Typography>
              </CardContent>
              <CardActions>
                <StyledButton component={Link} to="/new-request" variant="contained" fullWidth>
                  Start
                </StyledButton>
              </CardActions>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h5" component="div">
                  New Request For Someone Else
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Submit a new tuition reimbursement request on behalf of someone else.
                </Typography>
              </CardContent>
              <CardActions>
                <StyledButton component={Link} to="/user-search" variant="contained" fullWidth>
                  Start
                </StyledButton>
              </CardActions>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h5" component="div">
                  My Previous Requests
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  View and manage your previous tuition reimbursement requests.
                </Typography>
              </CardContent>
              <CardActions>
                <StyledButton component={Link} to="/previous-requests" variant="contained" fullWidth>
                  View
                </StyledButton>
              </CardActions>
            </StyledCard>
          </Grid>
        </Grid>
        }
      </Box>
    </Container>
  );
};

export default Home;
